import React, { useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import ThanksForSubmitting from './thanksForSubmitting';
import ResultAlreadyPresent from './resultAlreadyPresent';
import GoodSignal from './goodSignal';
import Troubleshoot from './troubleshoot';
import Failed from './failed';
import { FormComponent } from './forms/FormComponent';
import { useForm } from 'react-jeff';
import {
  useDateOfBirthField,
  useAccountNumber,
  useChannel888SelectionTypes,
} from './forms/formFields';
import { BLOCKS } from '@contentful/rich-text-types';
import { sendAnalytics } from '../../../helper';

interface InfoProps {
  module: any;
  sendBanner: any;
}

export const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
      return (
        <div style={{ marginBottom: '1em', whiteSpace: 'pre-line' }}>
          {children}
          <br />
        </div>
      );
    },
    [BLOCKS.UL_LIST]: (node: any, children: any) => (
      <ul className="list-disc list-outside ml-4 mb-4">{children}</ul>
    ),
    [BLOCKS.OL_LIST]: (node: any, children: any) => (
      <ol className="list-decimal list-outside ml-4 mb-4">{children}</ol>
    ),
    [BLOCKS.LIST_ITEM]: (node: any, children: any) => {
      return <li className="pl-1">{children}</li>;
    },
    hyperlink: (node: any, children: any) => {
      const uri = node?.data?.uri;

      if (!uri) {
        console.error('Invalid or missing URI:', node);
        return null;
      }

      // Check if the URI is a phone number
      const isPhoneNumber = uri.startsWith('tel:');

      // Function to detect if the device is mobile
      const isMobileDevice = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone|Opera Mini|IEMobile|Kindle|Silk|Tablet/i.test(
          navigator.userAgent,
        );
      };

      // If it's a phone number and the user is on a desktop, render non-clickable text
      if (isPhoneNumber && !isMobileDevice()) {
        return (
          <span style={{ color: 'black', cursor: 'not-allowed' }}>
            {children}
          </span>
        );
      }
      return (
        <>
          {children == `Book a technician` ? (
            <a
              href={node.data.uri}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-600 text-white font-medium rounded-full mb-6 w-[241px] h-[48px] py-[14px] px-[24px] focus:outline-none"
              onClick={(event) => {
                sendAnalytics("book_tech_clicked", { linkText: "Book a tech",linkUrl:node.data.uri});
              }}
            >
              {children}
            </a>
          ) : (
            <a
              href={node.data.uri}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'blue', textDecoration: 'underline' }}
            >
              {children}
            </a>
          )}
        </>
      );
    },
  },
};

const Information: React.FC<InfoProps> = ({ module, sendBanner }) => {
  const dateField = useDateOfBirthField();
  const accountNumberField = useAccountNumber();
  const [flag, setFlag] = useState<string | null>(null);
  const [additionalParam, setAdditionalParam] = useState<string | null>(null);

  const handleFormSubmit = (responseCode: string, additionalParam: any) => {
    setFlag(responseCode);
    setAdditionalParam(additionalParam);
  };

  const fields = [dateField, accountNumberField];

  const form = useForm({
    fields,
    onSubmit: () => {
      console.log('Form Submission from infor');
    },
  });

  const renderComponent = () => {
    switch (flag) {
      case 'NotYetReady':
        return (
          <Troubleshoot
            module={module}
            additionalParam={additionalParam}
            onSubmit={handleFormSubmit}
          />
        );
      case 'NotYetSet':
        return <Failed module={module} />;
      case '100':
        return <GoodSignal />;
      case '101':
        return <ResultAlreadyPresent />;
      case '103':
        return <ThanksForSubmitting />;
      // case '102':
      //   return <Failed module={module}/>;
      default:
        return (
          <div className="w-full md:w-1/2 px-4">
            <FormComponent
              form={form}
              dateField={dateField}
              accountNumberField={accountNumberField}
              onSubmit={handleFormSubmit}
            />
          </div>
        );
    }
  };
  return (
    <>
      <section className="bg-white py-8">
        <div className="container m-auto">
          {/*Heading Copntent*/}
          <div className="text-center mb-8">
            <h2 className="sky-h3 md:sky-h3-black sky-text-midnight md:py-4 px-4">
              {module?.productTitle}
            </h2>
          </div>
          <div className="flex flex-wrap md:items-start md:justify-between justify-center items-center ">
            {/* Text Content */}
            <div className="w-full md:w-1/2 px-4 md:pr-10 lg:pr-16">
              <div className="flex md:items-start md:justify-start h-full items-center justify-center">
                <div className="text-content">
                  {documentToReactComponents(module?.description, options)}
                </div>
              </div>
            </div>

            {/* Image Content */}
            {module?.internalName !== 'Satellite_Form' ? (
              <div className="w-full md:w-1/2">
                <div className="flex items-center justify-center">
                  <div className="overflow-hidden text-center max-w-md">
                    <img
                      alt={module?.bannerImage?.fields?.title}
                      className="w-full h-full object-cover"
                      src={module?.bannerImage?.fields?.file?.url}
                      // style={{ maxWidth: '522px', maxHeight: '294px' }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>{renderComponent()}</>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Information;
