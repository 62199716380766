import * as React from 'react';
import useSWR from 'swr'
import { Layout } from '../components'
import { agentContentfulClient } from '../services';

const fetcher = async () => {
    const entryItems = await agentContentfulClient.getEntries({ content_type: 'page' })
    return entryItems.items[0].fields
}

const Pages=  () => {
    const { data } = useSWR('contentful', fetcher)
    if(data) {
        return (
            <Layout data={data} />
        );
    }
    return null;
}

export default Pages;