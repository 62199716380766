import React, { useState } from "react";
import Accordion from "./Faq/accordion";



interface FaqSectionProps {
    module: any;
    sendBanner: any;
}

const FaqSection: React.FC<FaqSectionProps> = (module, sendBanner) => {
    const [hideFaq, setHideFaq] = useState(false);
    const faqs = module?.module?.faqsList;

    if (module === undefined || module.module.faqsList === undefined) {
        setHideFaq(true);
    }

    return (

        <div className="sky-bg-white pb-20">
            {!hideFaq && (
                <div className="container m-auto ">
                    <h2 className="sky-h3 md:sky-h3-black sky-text-midnight py-4 pt-16 pb-16 text-center">{module?.module?.heading}</h2>
                    <div className="m-auto">
                        {faqs?.map((faq: any, index: number) => (
                            <Accordion key={faq.fields.question} question={faq.fields.question} answer={faq.fields.answer} />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default FaqSection;