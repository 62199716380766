import React from 'react';

interface RadioButtonProps {
  id: string;
  label: string;
  name: string;
  value: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioButtonComponent: React.FC<RadioButtonProps> = ({ id, label, name, value, checked, onChange }) => {
  return (
    <div className='item-center flex font-bold pb-4'>
      <input
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id} className={`ml-2 text-center text-l font-bold  ${id === 'Pass' ? 'text-skyGreen' : 'sky-text-red'}`}>{label}</label>
    </div>
  );
};

export default RadioButtonComponent;
