import * as React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ReactComponent as NextIcon } from './assets/next-icon.svg'

interface LargeCallToActionProps {
    module: any;
    sendStandard: any;
}

const LargeCallToAction: React.FC<LargeCallToActionProps> = ({ module, sendStandard }) => {
    // console.log(module)
    return (
        <div className="large-call-to-action w-full relative ">
            <div className="max-w-[2000px] mx-auto bg-gradient-to-b from-[#E0E0E0] px-[30px] py-[50px] ">
                <div className="max-w-[1280px] mx-auto p-[20px]">
                    <div className={module.ctaHeadingGradient +" text-[30px] lg:text-[44px] leading-[32px] lg:leading-[44px]"}>
                        {documentToReactComponents(module.ctaHeading)}
                    </div>
                    <div className="w-full flex justify-center relative top-[20px] lg:top-[10px]">
                        <img className="" src={module.ctaImage.fields.file.url} alt={module.ctaHeading}/>
                    </div>
                    <div className="w-full flex justify-center relative -top-[30px]">
                        <div className="mt-[90px] lg:mt-[60px] text-[18px] text-sky-bright-blue flex justify-items-start">
                            {documentToReactComponents(module.ctaLink)} 
                            <NextIcon className="inline-block relative -top-[10px] ml-[15px]"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LargeCallToAction;