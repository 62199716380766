import * as React from 'react';

import ReactPlayerLoader from '@brightcove/react-player-loader';
import { HORIZONTAL_VIDEO_PLAYER_URL } from '../../../../config';

interface VideoPlayerProps {
  videoContentData: any;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoContentData }) => {

  const videoRef = React.useRef(null) as any;

  const baseURL = HORIZONTAL_VIDEO_PLAYER_URL.split('/');
  const accountId = baseURL[3];

  return (
    <ReactPlayerLoader attrs={{className: 'w-full h-full'}}
    accountId={accountId}
    videoId={videoContentData.brightCoveId}
    options={{
      autoplay: videoContentData.autoplay,
      muted: videoContentData.autoplay === false ? false : true,
      loop: videoContentData.autoplay === false ? false : true,
      playsinline: true,
    }}
    ref={videoRef}
  />
  );
};

export default VideoPlayer;
