import React from "react";

const ResultAlreadyPresent: React.FC = () => {

    return (
        <>
            <div className="w-full md:w-1/2 px-4">
                <div className="flex flex-col items-center justify-center ">
                    {/* Checkmark Icon */}
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M26 15.0857V16.0057C25.9988 18.1621 25.3005 20.2604 24.0093 21.9875C22.7182 23.7147 20.9033 24.9782 18.8354 25.5896C16.7674 26.201 14.5573 26.1276 12.5345 25.3803C10.5117 24.633 8.78465 23.2518 7.61096 21.4428C6.43727 19.6337 5.87979 17.4938 6.02168 15.342C6.16356 13.1903 6.99721 11.142 8.39828 9.50279C9.79935 7.86355 11.6928 6.72111 13.7962 6.24587C15.8996 5.77064 18.1003 5.98806 20.07 6.86573M26 8.00574L16 18.0157L13 15.0157" stroke="#0BAA4B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>


                    {/* Thank You Message */}
                    <h1 className="text-2xl font-bold sky-text-midnight text-gray-800 mb-4 max-w-md">
                        We seem to already have a test result for the account details you’ve entered.
                    </h1>

                    {/* Secondary Message */}
                    <p className="text-gray-600 text-center max-w-md">
                    And you’re in the prize draw.<a href="https://www.sky.co.nz/competition-terms" className="text-blue-500 cursor-pointer hover:underline"> T&Cs apply</a>.
                    </p>
                    <p className="text-gray-600 text-center max-w-md">
                        If you need to check or change the result, please call us on <a href="tel:+6495255555" className="text-blue-500 cursor-pointer hover:underline">09 525 5555</a> and say ‘Satellite Switch’ when prompted.
                    </p>
                </div>
            </div>
        </>
    );

}

export default ResultAlreadyPresent;