export const sendAnalytics = async (
    event_name: string,
    extraParam: any,
  ): Promise<void> => {
    const commonParams = {
      title: document.title,            // Page title
      url: window.location.href,        // Full URL of the page
      path: window.location.pathname,   // Path (e.g., /home, /about)
      referrer: document.referrer,      // Referrer (if available)
    };
    const segmentSent = new Promise<void>((resolve, reject) => {
      window.analytics?.track(
        event_name,
        { ...commonParams, ...extraParam },
      );
      resolve();
    });
  
    return segmentSent.then(() => {});
  };
  