import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React, { useState } from 'react';
import { options } from './Info';
import { agentSatellite } from '../../../services';
import { Loader } from '@sky-tv-group/components';
import { sendAnalytics } from '../../../helper';

interface ChildComponentProps {
  module: any;
  additionalParam: any;
  onSubmit: (responseCode: string, additionalParam?: any) => void;
}

export const Troubleshoot: React.FC<ChildComponentProps> = ({
  module,
  additionalParam,
  onSubmit,
}) => {
  const [isSubmitLoading, setSubmitLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setSubmitLoading(true);
    additionalParam.testResult = 'Pass';
    additionalParam.inputType = 'ReCapture';

    const response = await agentSatellite.post(
      '/order/satelliteMigrateOrdersProcessor',
      additionalParam,
    );
    const data = await response.data;
    onSubmit(data.responseCode, additionalParam);
    sendAnalytics("submit_clicked", {accountNumber:additionalParam.custIdentifier, linkText:'Good to go', alertMessage:(data.responseCode != '100' && data.responseCode != '101') ? data.responseCode + " - The Account or Smartcard number and account holder’s date of birth details don’t match. Please check and try again." : "success"});
    setSubmitLoading(false);
  };
  const handleSubmitNotSet = async (event: React.FormEvent) => {
    event.preventDefault();
    sendAnalytics("submit_clicked", {accountNumber:additionalParam.custIdentifier, linkText:'Not yet ready', alertMessage:"success"});
    onSubmit('NotYetSet', additionalParam);
  };
  return (
    <>
      <div className="w-full md:w-1/2 px-4">
        <div className="flex flex-col items-center justify-center">
          {/* Heading */}
          {documentToReactComponents(module?.extraDescription, options)}

          {/* Buttons */}
          <div className="flex space-x-4 items-center justify-center">
            {/* Not Yet Ready Button */}
            <button
              className="bg-red-500 text-white font-medium py-2 px-6 rounded-full focus:outline-none w-[235.5px] h-[48px] py-[14px] px-[24px] gap-[8px]"
              style={{ background: '#F32836' }}
              onClick={handleSubmitNotSet}
            >
              Not yet ready
            </button>

            {/* Good to Go Button */}
            <button
              className="bg-green-500 text-white font-medium py-2 px-6 rounded-full focus:outline-none w-[235.5px] h-[48px] py-[14px] px-[24px] gap-[8px]"
              style={{ background: '#0BAA4B' }}
              onClick={handleSubmit}
            >
              {isSubmitLoading ? (
                <div className="relative h-4 w-24">
                  <Loader bgColor=""></Loader>
                </div>
              ) : (
                'Good to go'
              )}{' '}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Troubleshoot;
