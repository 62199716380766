import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React from 'react';

interface Channel888Props {
  module: any;
  sendBanner: any;
}

const Channel888: React.FC<Channel888Props> = ({ module, sendBanner }) => {
  return (
    <>
      <section className="bg-white py-8">
        <div className="container m-auto">
          {/*Heading Copntent*/}
          <div className="text-center mb-8">
            <h2 className="sky-h3 md:sky-h3-black sky-text-midnight md:py-4 px-4">
              {module?.heading}
            </h2>
            <p className="text-base text-gray-600">
              {documentToReactComponents(module?.detailsdescription)}
            </p>
          </div>

          <div className="flex flex-wrap items-center justify-center">
            {module.galleryContent.map((item: any, index: number) => {
              return (
                <div className="w-full md:w-1/2 px-4" key={index}>
                  <div className="flex items-center justify-center">
                    <div className="overflow-hidden text-center max-w-md">
                      <img
                        alt="Satellite Diagram"
                        className="w-full h-auto"
                        src={item.fields.contentThumbnail.fields.file.url}
                        style={{ maxWidth: '481px' }}
                      />
                    </div>
                  </div>
                  <div className="text-content">
                    <div className="flex justify-center py-3">
                      <img
                        alt="Satellite Diagram"
                        src={
                          item.fields.contentDescription.content[0].data.target
                            .fields.file.url
                        }
                      />
                      <p className={`mt-2 ml-2 text-center text-2xl font-bold  ${index === 0 ? 'text-skyGreen' : 'sky-text-red'}`}>
                        {
                          item.fields.contentDescription.content[1].content[0]
                            .value
                        }
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Channel888;
