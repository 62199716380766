import React, { useState } from 'react';
import { caret } from '@sky-tv-group/components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { options } from '../Info';

interface AccordionProps {
  question: string;
  answer: any;
}

const Accordion = ({ question, answer }: AccordionProps) => {
  const [active, setActiveState] = useState(false);

  return (
    <div className="py-2 border-t" id={question.split(/\s+/).join("-")} data-testid={`accordion-${question}`}>
      <div
        onClick={() => setActiveState(!active)}
        className="cursor-pointer flex flex-row items-center justify-between">
        <h5 className="sky-text-midnight p-1 sky-h6-bold md:sky-h6-bold">{question}</h5>
        <button className={`${active ? 'transform -rotate-180 pl-4' : 'pr-4'} w-12 h-12`}>{caret}</button>
      </div>
      <div className={`${active ? 'h-auto' : 'h-0'} overflow-hidden`}>
        <p className='pl-1 pr-4 md:pr-18 pr-8'>{documentToReactComponents(answer , options)}</p>
      </div>
    </div>
  );
};

export default  Accordion;
