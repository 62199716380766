import React, { ReactNode } from 'react';
import {
  Options,
  documentToReactComponents,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';

const renderOptions: Options = {
  renderText: (text: string) =>
    text
      .split('\n')
      .reduce(
        (children: any, textSegment: any, index: number) => [
          ...children,
          index > 0 && <br key={index} />,
          textSegment,
        ],
        [],
      ),
  renderNode: {
    [BLOCKS.UL_LIST]: (_, children: ReactNode) => (
      <ul className="text-base ml-2" style={{ listStyleType: "'✓'" }}>
        {children}
      </ul>
    ),
    [BLOCKS.LIST_ITEM]: (_, children: ReactNode) => (
      <li className="pl-1 mb-2">{children}</li>
    ),
  },
};
export interface bannerContents {
  bannerContent?: BannerContent;
}
export interface BannerContent {
  bannerName?: string;
  heading?: Document;
  detailParagraph?: Document;
  copyrightParagraph?: Document;
  heroImage?: Image;
  channelOrSponsorLogos?: Image;
  heroImageMobile?: Image;
  brightCoveVideoId?: string;
  brightCoveVideoIdMobile?: string;
  callToActionText?: string;
  callToActionLink?: string;
  callToActionSecondaryText?: string;
  callToActionLinkSecondary?: string;
}

interface Image {
  url?: string;
  text?: string;
}

//start
const SingleBannerThirdWidthComponentHomePage = ({
  bannerContent,
  contentWidth,
  topCallLink,
  sendBanner,
}: any) => {
  return (
    <>
      <div
        className={`lg:right-4  md:top-2 md:-right-1 mt-16 lg:mt-24 ${
          contentWidth ? '' : 'left-2 top-20'
        }`}
        style={{ width: contentWidth, left: '2rem' }}
      >
        {bannerContent?.detailParagraph && (
          <div
            className="lg:mt-28 lg:text-xl md:mt-16 lg:ml-0 sky-h6  md:sky-h8 text-white text-base font-normal"
            style={{ width: '343px' }}
          >
            {documentToReactComponents(
              bannerContent?.detailParagraph as any,
              renderOptions,
            )}
          </div>
        )}
        {bannerContent?.callToActionText && (
          <div
            style={{ top: `${topCallLink}` }}
            className={`md:mt-4 lg:flex gap-8 md:text-base font-bold  lg:top-60  lg:ml-0`}
          >
            <div>
              <a href={bannerContent?.callToActionLink}>
                <button
                  style={{ color: 'blue', width: '235px', height: '48px' }}
                  className="bg-white  hover:bg-social-twitter hover:text-white sky-h7 font-bold py-4 md:px-4  rounded-full"
                  onClick={(e) => {
                    e.preventDefault();
                    sendBanner(
                      'Promotion Clicked ',
                      bannerContent?.callToActionText,
                      bannerContent?.callToActionLink,
                      bannerContent?.heroImage?.fields.file.url,
                      bannerContent?.heading?.content?.[0]?.content?.[0]?.value,
                      'banner',
                    );
                  }}
                >
                  {bannerContent?.callToActionText}
                </button>
              </a>
            </div>
            {bannerContent?.callToActionSecondaryText &&
              !bannerContent?.isMobileSecondaryLink && (
                <div className="lg:mt-0 lg:ml-1 md:mt-6 md:ml-8">
                  <a
                    href={bannerContent?.callToActionLinkSecondary}
                    onClick={(e) => {
                      e.preventDefault();
                      sendBanner(
                        'Promotion Clicked ',
                        bannerContent?.callToActionSecondaryText,
                        bannerContent?.callToActionLinkSecondary,
                        bannerContent?.heroImage?.fields.file.url,
                        bannerContent?.heading?.content?.[0]?.content?.[0]
                          ?.value,
                        'banner',
                      );
                    }}
                  >
                    <p className="underline sky-h5 font-bold text-white py-4">
                      {bannerContent?.callToActionSecondaryText}
                    </p>
                  </a>
                </div>
              )}
          </div>
        )}
        {bannerContent?.copyrightParagraph && (
          <div className="mt-4" style={{ width: '340px', fontSize: '10px' }}>
            <h4 className="font-skyBook text-white">
              {documentToReactComponents(
                bannerContent?.copyrightParagraph as any,
                renderOptions,
              )}
            </h4>
          </div>
        )}
      </div>
    </>
  );
};
//end//

export const WebContentHomePageBanner = ({
  bannerContent,
  sendBanner,
}: any) => {
  let backgroundColorGradient: string;
  let backgroundColorGradientMobile: string;

  switch (bannerContent.heroBrandBackground?.[0]?.replaceAll(' ', '')) {
    case '30/60Purple-DarkBlue':
      backgroundColorGradient =
        'radial-gradient(199.72% 156.98% at 110.9% -11.1%, #0C97E4 0%, #1A49BA 50%, #163EA7 59%, #0C2277 79%, #01003D 100%)';
      backgroundColorGradientMobile =
        'radial-gradient(199.72% 156.98% at 110.9% -11.1%, #0C97E4 0%, #1A49BA 50%, #163EA7 59%, #0C2277 79%, #01003D 100%)';
      break;
    case '50/50GreenTeal':
      backgroundColorGradient =
        'radial-gradient(100% 140.64% at -4% 100%, rgb(37, 74, 179) 0%, rgb(42, 197, 244) 50%, rgb(11, 170, 75) 103%)';
      backgroundColorGradientMobile =
        'radial-gradient(180% 140.64% at -4% 100%, rgb(37, 74, 179) 0%, rgb(42, 197, 244) 45%, rgb(11, 170, 75) 80%)';
      break;
    case '30/60Pink-DarkBlue':
      backgroundColorGradient =
        'radial-gradient(140.5% 158.11% at 90.8% -1.8%, #F32936 20%, #344AA2 49.48%, #0D1137 100%)';
      backgroundColorGradientMobile =
        'radial-gradient(140.5% 158.11% at 90.8% -1.8%, #F32936 20%, #344AA2 49.48%, #0D1137 100%)';
      break;
    case '50/50Pink-Orange':
      backgroundColorGradient =
        'radial-gradient(100% 140.64% at 0% 100%, rgb(37, 74, 179) 0%, rgb(249, 76, 177) 63%, rgb(255, 158, 28) 100%)';
      backgroundColorGradientMobile =
        'radial-gradient(170% 100.64% at 0% 100%, rgb(37, 74, 179) 0%, rgb(249, 76, 177) 55%, rgb(255, 158, 28) 95%)';
      break;
    case '70/30DarkBlue-SkyBlue':
      backgroundColorGradient =
        'radial-gradient(193.6% 149.14% at 105.22% -5.7%, #2AC5F4 30%, #254AB3 43.82%, #18308A 60.59%, #01003D 100%)';
      backgroundColorGradientMobile =
        'radial-gradient(193.6% 149.14% at 105.22% -5.7%, #2AC5F4 30%, #254AB3 43.82%, #18308A 60.59%, #01003D 100%)';
      break;
    default:
      backgroundColorGradient =
        'radial-gradient(circle at top left, #363FBC 0%, #00B1EB 100%)';
      backgroundColorGradientMobile =
        'radial-gradient(circle at top left, #363FBC 0%, #00B1EB 100%)';
      break;
  }
  return (
    <>
      {/* Mobile view */}
      <div
        className="lg:hidden md:hidden flex h-[588px]"
        style={{ backgroundImage: backgroundColorGradientMobile }}
      >
        <div
          className="flex bg-no-repeat bg-cover md:hidden lg:hidden relative"
          style={{
            backgroundImage: `url('${
              bannerContent?.heroImageMobile?.fields?.file?.url
                ? bannerContent?.heroImageMobile?.fields?.file.url
                : bannerContent?.heroImage?.fields?.file?.url
            }')`,
            clipPath: 'polygon(0 0, 100% 0, 100% 86%, 0% 100%)',
            width: '100%',
            height: '208px',
          }}
        ></div>
        <div
          className="absolute text-white container m-auto"
          style={{
            top: '16.5rem',
            width: 'full',
            fontFamily: 'MarkPro-Bold SKY',
          }}
        >
          {bannerContent?.channelOrSponsorLogos?.[0]?.fields?.file?.url && (
            <div className="px-4 mt-6">
              <img
                src={
                  bannerContent?.channelOrSponsorLogos?.[0]?.fields?.file?.url
                }
                alt="channel logo"
                className="object-contain h-[2.5rem]"
              />
            </div>
          )}
          <div
            className={`px-4 font-skyBlack text-white ${
              !bannerContent?.channelOrSponsorLogos?.[0]?.fields?.file?.url &&
              'mt-6'
            }`}
            style={{ fontSize: '28px', width: 'full' }}
          >
            {documentToReactComponents(bannerContent?.heading as any)}
          </div>
          {bannerContent?.detailParagraph && (
            <div
              className="px-4 font-skyBook text-white whitespace-pre-line"
              style={{ minHeight: '4rem', fontSize: '16px', width: 'full' }}
            >
              {documentToReactComponents(
                bannerContent?.detailParagraph as any,
                renderOptions,
              )}
            </div>
          )}

          {bannerContent?.callToActionText && (
            <div
              className="gap-4 text-2xl"
              style={{ margin: '.5rem 1rem' }}
            >
              <div>
                <a href={bannerContent?.callToActionLink}>
                  <button
                    style={{ color: 'blue' }}
                    className="bg-white w-full hover:bg-social-twitter hover:text-white sky-h7 font-bold py-4 px-10 rounded-full"
                    onClick={(e) => {
                      e.preventDefault();
                      sendBanner(
                        'Promotion Clicked ',
                        bannerContent?.callToActionText,
                        bannerContent?.callToActionLink,
                        bannerContent?.heroImage?.fields.file.url,
                        bannerContent?.heading?.content?.[0]?.content?.[0]
                          ?.value,
                        'banner',
                      );
                    }}
                  >
                    {bannerContent?.callToActionText}
                  </button>
                </a>
              </div>
              {bannerContent?.callToActionSecondaryText && (
                <div>
                  <a
                    href={bannerContent?.callToActionLinkSecondary}
                    onClick={(e) => {
                      e.preventDefault();
                      sendBanner(
                        'Promotion Clicked ',
                        bannerContent?.callToActionSecondaryText,
                        bannerContent?.callToActionLinkSecondary,
                        bannerContent?.heroImage?.fields.file.url,
                        bannerContent?.heading?.content?.[0]?.content?.[0]
                          ?.value,
                        'banner',
                      );
                    }}
                  >
                    <p className="underline sky-h5 font-bold text-white text-center my-4 mt-8 mb-4">
                      {bannerContent?.callToActionSecondaryText}
                    </p>
                  </a>
                </div>
              )}
            </div>
          )}
          {bannerContent?.copyrightParagraph && (
            <h4
              className="font-skyBook px-4 text-white"
              style={{ fontSize: '12px', marginTop: '8px', width: 'full' }}
            >
              {documentToReactComponents(
                bannerContent?.copyrightParagraph as any,
                renderOptions,
              )}
            </h4>
          )}
        </div>
      </div>

      {/* Desktop view */}
      <div
        className="hidden  lg:flex md:hidden relative "
        style={{ backgroundImage: backgroundColorGradient }}
      >
        <div
          className="hidden  lg:flex relative bg-cover"
          style={{
            backgroundImage: `url(${bannerContent?.heroImage?.fields?.file.url})`,
            height: '588px',
            width: '100%',
            right: '-5%',
            clipPath: 'polygon(45% 0px, 100% 0px, 100% 100%, 38% 100%)',
            backgroundSize: '100% 100%',
          }}
        ></div>
        <div className="lg:ml-20 absolute lg:left-0" style={{ top: '5rem' }}>
          <div className="font-bold text-white">
            {bannerContent?.channelOrSponsorLogos?.[0]?.fields?.file?.url && (
              <img
                src={
                  bannerContent?.channelOrSponsorLogos?.[0]?.fields?.file?.url
                }
                alt="channel logo"
                className="lg:h-12 md:h-6"
              />
            )}
            <div
              style={{ fontFamily: 'MarkPro-Black SKY', width: '524px' }}
              className="text-white md:text-5xl font-black mt-6"
            >
              {documentToReactComponents(
                bannerContent?.heading as any,
                renderOptions,
              )}
            </div>
          </div>
          <div className={`mt-6`} style={{ width: '524px', height: '163px' }}>
            {bannerContent?.detailParagraph && (
              <div className="lg:text-xl sky-h6  md:sky-h8 text-white text-base font-normal lg:w-full md:w-11/12">
                {documentToReactComponents(
                  bannerContent?.detailParagraph as any,
                  renderOptions,
                )}
              </div>
            )}
          </div>
          <div className={`mt-6 lg:flex gap-8 md:text-base font-bold`}>
            {bannerContent?.callToActionText && (
              <div>
                <a href={bannerContent?.callToActionLink}>
                  <button
                    style={{ color: 'blue', width: '235px', height: '48px' }}
                    className="bg-white  hover:bg-social-twitter hover:text-white sky-h7 font-bold py-4 md:px-4  rounded-full"
                    onClick={(e) => {
                      e.preventDefault();
                      sendBanner(
                        'Promotion Clicked ',
                        bannerContent?.callToActionText,
                        bannerContent?.callToActionLink,
                        bannerContent?.heroImage?.fields.file.url,
                        bannerContent?.heading?.content?.[0]?.content?.[0]
                          ?.value,
                        'banner',
                      );
                    }}
                  >
                    {bannerContent?.callToActionText}
                  </button>
                </a>
              </div>
            )}
            {bannerContent?.callToActionSecondaryText &&
              !bannerContent?.isMobileSecondaryLink && (
                <div className="lg:mt-0 lg:ml-1 md:mt-6 md:ml-8">
                  <a
                    href={bannerContent?.callToActionLinkSecondary}
                    onClick={(e) => {
                      e.preventDefault();
                      sendBanner(
                        'Promotion Clicked ',
                        bannerContent?.callToActionSecondaryText,
                        bannerContent?.callToActionLinkSecondary,
                        bannerContent?.heroImage?.fields.file.url,
                        bannerContent?.heading?.content?.[0]?.content?.[0]
                          ?.value,
                        'banner',
                      );
                    }}
                  >
                    <p className="underline sky-h5 font-bold text-white py-4">
                      {bannerContent?.callToActionSecondaryText}
                    </p>
                  </a>
                </div>
              )}
          </div>

          {bannerContent?.copyrightParagraph && (
            <div className="mt-6">
              <h4
                className="font-skyBook md:sky-h8 text-white"
                style={{ fontSize: '12px', width: '32rem' }}
              >
                {documentToReactComponents(
                  bannerContent?.copyrightParagraph as any,
                  renderOptions,
                )}
              </h4>
            </div>
          )}
        </div>
      </div>

      {/* Tablet view */}
      <div
        className="hidden  md:flex lg:hidden relative"
        style={{ backgroundImage: backgroundColorGradient }}
      >
        <div
          className="hidden md:flex relative w-100 h-100 d-flex relative bg-center bg-cover pt-72 pb-12 md:pt-32 md:pb-10 text-center lg:text-left px-6"
          style={{
            backgroundImage: `url(${bannerContent?.heroImage?.fields?.file.url})`,
            height: '440px',
            width: '100%',
            right: '-4%',
            clipPath: 'polygon(49% 0px, 100% 0px, 100% 100%, 42% 100%)',
            backgroundSize: '100% 100%',
            // backgroundPosition: 'left'
          }}
        ></div>
        <div className="absolute font-bold text-white lg:top-48 lg:left-4 md:top-12 md:left-8">
          {bannerContent?.channelOrSponsorLogos?.[0]?.fields?.file?.url && (
            <img
              src={bannerContent?.channelOrSponsorLogos?.[0]?.fields?.file?.url}
              alt="channel logo"
              className="lg:h-12 md:h-6"
            />
          )}
          <div className="sky-h2 md:sky-h4 text-white mt-2">
            {documentToReactComponents(
              bannerContent?.heading as any,
              renderOptions,
            )}
          </div>
          <SingleBannerThirdWidthComponentHomePage
            bannerContent={bannerContent}
            contentWidth="29rem"
            topCallLink="6rem"
            sendBanner={sendBanner}
          />
        </div>
      </div>
    </>
  );
};
