import useSWR from 'swr'
import { Layout } from '../components'
import { Auth0Provider } from '../store/authStore/auth0';
import config from '../store/authStore/auth0.config';
import { useAuth0 } from '../store/authStore/authStore';
import { DOMAIN, LIFERAY_CONNECT_ENDPOINT } from '../config';
import {NotificationManager} from '@sky-tv-group/components';
import { useLocation } from 'react-router-dom';
import { agentContentfulClient } from '../services';

const queryContentful = async (pathname: string): Promise<any> => {
  const paramsPath = `${pathname}`;
  const entryItems = await agentContentfulClient.getEntries({
    content_type: 'home',
    'fields.slug': paramsPath.replace(/\/undefined/g, ''),
    limit: 1,
    include: 10,
  });

  if (entryItems.items[0]?.fields) {
    return entryItems.items[0].fields;
  }
  return { status: false };
};


const Home = () => {
    const { pathname } = useLocation(); // Get the pathname
    const onRedirectCallback = () => {
      window.history.replaceState({}, document.title, '/');
    };

    const { data } = useSWR(pathname, queryContentful)

    function AuthenticatedApp() {
        const { loading } = useAuth0();
        return loading ? <Layout data={data}/> : <Layout data={data}/>;
      }

    if(data) {
        return (
          <>
          <NotificationManager notificationsEndpoint={LIFERAY_CONNECT_ENDPOINT} domain={DOMAIN} />
              <Auth0Provider
                config={{
                  domain: config.domain,
                  client_id: config.clientId,
                  redirect_uri: window.location.origin,
                  audience: config.audience,
                }}
                onRedirectCallback={onRedirectCallback}>
                <AuthenticatedApp/>
          </Auth0Provider>
        </>
        );
    }
    return null;
}

export default Home;