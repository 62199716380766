import * as React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ReactComponent as GreenTealSky } from '../assets/green-teal-sky.svg'
import { ReactComponent as PinkOrangeSky } from '../assets/pink-orange-sky.svg'
import { ReactComponent as GreenTeal } from '../assets/green-teal-mobile.svg'
import { ReactComponent as PinkOrange } from '../assets/pink-orange-mobile.svg'
import { HORIZONTAL_VIDEO_PLAYER_URL } from '../../../config';
import ReactPlayerLoader from '@brightcove/react-player-loader';

interface FiftyFiftyProps {
    module: any;
    height: number;
    width: number;
    sendBanner: any;
}

const FiftyFifty: React.FC<FiftyFiftyProps> = ({ module, height, width, sendBanner }) => {

    let backgroundSky
    let backgroundMobile

    const baseURL = HORIZONTAL_VIDEO_PLAYER_URL.split('/');
    const accountId = baseURL[3];
    const videoID = module.fields.brightCoveVideoId;

    switch(module.fields.heroBrandBackground[0]) {
        case "50/50 Pink - Orange":
            backgroundSky = <PinkOrangeSky className="h-full"/>
            backgroundMobile = <PinkOrange className="h-[300px] md:h-[46vh] "/>
        break;
        default:
            backgroundSky = <GreenTealSky className="h-full"/>
            backgroundMobile = <GreenTeal className="h-[300px] md:h-[46vh]"/>
    }

    return (
        <div className="content-banner fifty-fifty relative w-full max-w-[2000px] overflow-hidden shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] bg-black" style={{height: height}}>
            <div className="relative bg-no-repeat lg:grid lg:grid-cols-12" style={{height: height, backgroundPosition: '-43% top', backgroundSize: '73%', backgroundImage: width > 1024 ?  `url('`+module.fields.heroImage?.fields.file.url+`')` : ""}}>
                <div className="relative bg-no-repeat h-[375px] lg:col-span-7 lg:h-full" style={{backgroundPosition: 'center top', backgroundSize: '130%', height: height, backgroundImage: width < 1024 ?  `url('`+module.fields.heroImageMobile?.fields.file.url+`')` : ""}}>
                    {module.fields.brightCoveVideoId ?
                        <div className="mx-auto bg-black" style={{height: height, width: height*1.77777}}>
                            <ReactPlayerLoader attrs={{height: width < 1024 ? width * .5625 : height+"px"}} accountId={accountId} videoId={videoID} options={{ autoplay: width < 1024 ? false : true, controls: false, muted: true }} />
                        </div>
                    : null }
                    <div className="absolute bottom-0 bg-gradient-to-t from-black to-transparent h-[60px] w-full opacity-[.6]"></div>
                </div>
                <div className="overflow-hidden absolute bottom-0 md:h-[32vh] lg:col-span-5 lg:min-h-[50vh] lg:-ml-[30%] lg:relative lg:z-[1] lg:h-full">
                    {width > 1024 ? backgroundSky : backgroundMobile}
                </div>
            </div>
            <div className="absolute bottom-[20px] left-0 w-full z-[2] lg:grid lg:grid-cols-2 lg:h-full">
                <div className="left-hand relative w-full p-[20px] md:pl-[40px] lg:pl-[60px] lg:col-span-1 lg:h-auto">
                    <div className={module.fields.copyrightPosition === true ? "absolute text-[9px] leading-[10px] text-white" : "absolute top-[34vh] md:top-[20vh] lg:bottom-[8px] lg:top-auto text-[9px] leading-[10px] text-white"}>{documentToReactComponents(module.fields.copyrightParagraph)}</div>
                    {module.fields.channelOrSponsorLogos ? 
                        <div className="grid grid-cols-2 h-full" >
                            <div className="col-span-1 relative">
                                <img className="absolute bottom-[20px] left-0 max-h-[40px] max-w-[100px] object-contain" src={module.fields.channelOrSponsorLogos[0]?.fields.file.url} alt=""/>
                            </div>
                            <div className="col-span-1 relative">
                                <img className="absolute bottom-[20px] right-0 max-h-[40px] max-w-[100px] object-contain" src={module.fields.channelOrSponsorLogos[1]?.fields.file.url} alt=""/>
                            </div>
                        </div>
                    : null } 
                </div>
                <div className="relative right-hand -top-[30px] p-[20px] md:top-[0] md:pl-[40px] lg:pl-[60px] lg:col-span-1 lg:h-full ">
                    <div className="mt-[0] font-sky-white text-white text-[30px] leading-[32px] uppercase sky-h1-bold lg:mt-[30px] lg:max-w-[300px] lg:absolute lg:text-[40px] lg:w-[43%] lg:leading-[40px] lg:top-[45%]">
                        {documentToReactComponents(module.fields.heading)}
                    </div>
                    <div className="mt-[15px] md:mt-[30px] lg:absolute lg:bottom-[40px]">
                        <div className="mb-[20px] relative -ml-[.3rem] lg:-ml[1rem]">
                        { module.fields.callToActionText ?
                            <button className="sky-button sky-button--primary-pure relative" onClick={(e)=>{ e.preventDefault(); sendBanner("Promotion Clicked ", module.fields.callToActionText, module.fields.callToActionLink, module.fields.heroImage.fields.file.url, module.fields.heading?.content[0]?.content[0]?.value, "banner")}}>{module.fields.callToActionText}</button>
                        : null }
                        { module.fields.callToActionSecondaryText ?
                            <button className="sky-button sky-button--secondary-white" onClick={(e)=>{ e.preventDefault(); sendBanner("Promotion Clicked ", module.fields.callToActionSecondaryText, module.fields.callToActionLinkSecondary, module.fields.heroImage?.fields.file.url, module.fields.heading?.content[0]?.content[0]?.value, "banner")}}>{module.fields.callToActionSecondaryText}</button>
                        : null }
                        </div>
                        <div className="text-white text-[15px] leading-[19px] mr-[16px] lg:text-[17px] lg:max-w-[500px]" >
                            {documentToReactComponents(module.fields.detailParagraph)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FiftyFifty;

