import React from 'react';
import cx from 'classnames';

interface Props {
  className?: string;
  onClick?: (e: any) => void;
  invert: boolean;
  colorClassName?: string;
}

const Arrow: React.FC<Props> = ({ className, onClick, invert, colorClassName = 'text-black' }: Props) => {
  const arrowClasses = cx('w-12 h-12 md:w-16 md:h-16 z-10', colorClassName, {
    'transform rotate-180': invert,
  });

  return (
    <>
      <button className={className} onClick={onClick}>
        <div className={arrowClasses}>{ArrowSVG}</div>
      </button>
    </>
  );
};

const ArrowSVG = (
  <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 100 100">
    <path
      d="M50 10.8c-21.6 0-39.2 17.6-39.2 39.2S28.4 89.2 50 89.2 89.2 71.6 89.2 50 71.6 10.8 50 10.8zm0 76.4c-20.5 0-37.2-16.7-37.2-37.2S29.5 12.8 50 12.8 87.2 29.5 87.2 50 70.5 87.2 50 87.2z"
      fill="currentColor"></path>
    <path
      d="M61.1 48.1L39.9 26.9c-.2-.2-.4-.3-.7-.3-.6 0-1 .4-1 1 0 .3.1.5.3.7l21.2 21.2c.3.3.3.7 0 .9L38.5 71.7c-.2.2-.3.4-.3.7 0 .6.4 1 1 1 .3 0 .5-.1.7-.3l21.2-21.2c1-1.1 1-2.7 0-3.8z"
      fill="currentColor"></path>
  </svg>
);

export { Arrow };