import * as React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

interface ThirtySixtyProps {
    module: any;
    width: number;
    height: number;
    sendBanner: any;
}

const ThirtySixty: React.FC<ThirtySixtyProps> = ({ module, width, height, sendBanner }) => {
    // console.log(module)
    let backgroundSrcImage = "/purple-dark-blue.svg";
    
    switch(module.fields.heroBrandBackground[0]) {
        case "30/60 Purple - Dark Blue":
            backgroundSrcImage = "/purple-dark-blue.svg";
        break;
        default:
            backgroundSrcImage = "/pink-dark-blue.svg";
    }
    return (
        <div className="content-banner thirty-sixty relative overflow-hidden md:min-h-[auto] w-full lg:h-auto bg-no-repeat bg-[length:100%] lg:bg-[length:77%] bg-right"
            style={{height: height, backgroundImage: width > 1024 ? `url('`+module.fields.heroImage.fields.file.url+`')` : `url('`+module.fields.heroImageMobile.fields.file.url+`')`}}>
            <div className="hidden lg:block relative w-auto h-full left-0" >
                <img className="w-[39vw] object-center object-cover" src={backgroundSrcImage} alt={module.fields.heroImage.fields.file.title}/>
            </div>
            <div className="grid grid-cols-2 absolute h-full w-full p-[20px] z-10 top-0">
                <div className="col left-hand w-[38vw]">
                    
                </div>
                <div className="col relative right-hand w-[48vw] h-full text-right">
                    {documentToReactComponents(module.fields.copyrightParagraph)}
                </div>
            </div>
            {module.fields.channelOrSponsorLogos ? 
                <div className="grid grid-cols-2 absolute bottom-[20px] right-[20px] w-full left-[20px] lg:left-[34vw] lg:w-[66vw] align-middle">
                    <img className="max-h-[40px]" src={module.fields.channelOrSponsorLogos[1]?.fields.file.url ? module.fields.channelOrSponsorLogos[0]?.fields.file.url : "/blank.png"} alt=""/>
                    <img className="max-h-[40px] absolute right-[30px] bottom-0" src={module.fields.channelOrSponsorLogos[1]?.fields.file.url ? module.fields.channelOrSponsorLogos[1].fields.file.url : module.fields.channelOrSponsorLogos[0].fields.file.url} alt=""/>
                </div>
            : null }
        </div>
    );
}

export default ThirtySixty;