import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import React from "react";
import { options } from "./Info";
interface FailedProps {
    module: any;
}
  

const Failed: React.FC<FailedProps> = ({ module }) => {
    return (
        <>
            <div className="w-full md:w-1/2 px-4">
                <div className="flex flex-col text-gray-800">
                    {/* Heading */}
                    {documentToReactComponents(module?.secondaryInformation, options)}
                </div>
            </div>
        </>
    );

}

export default Failed;