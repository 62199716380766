import React from 'react';

interface BannerSectionProps {
  module: any;
  sendBanner: any;
}

const Bannersection: React.FC<BannerSectionProps> = ({
  module,
  sendBanner,
}) => {
  return (
    <>
      <section className="bg">
        <div className="container-fluid px-0 py-0">
          <div className="row no-gutters">
            <div className="col-12">
              <div className="w-full object-cover">
                <div className="md:hidden flex">
                  <div className="flex bg-no-repeat bg-cover md:hidden relative">
                    <img
                      src={
                        module?.mobileImage?.fields?.file?.url
                          ? module?.mobileImage?.fields?.file.url
                          : module?.image?.fields?.file?.url
                      }
                      alt={module?.image?.fields?.title}
                      style={{
                        width: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                </div>

                {/* Desktop view */}
                  <div className="hidden  md:flex relative bg-cover">
                    <img
                      src={module?.image?.fields?.file.url}
                      alt={module?.image?.fields?.title}
                      style={{
                        width: '100%',
                        objectFit: 'cover',
                      }}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Bannersection;
