import {
  Button,
  FormElement,
  Loader,
  ValidatedTextInput,
  ValidationDatePicker,
} from '@sky-tv-group/components';
import React, { useState } from 'react';
import { Field, Form } from 'react-jeff';
import { format, subYears } from 'date-fns';
import { useToastContainer } from '@sky-tv-group/shared';
import RadioButtonComponent from './RadioButtonComponent';
import { agentSatellite } from '../../../../services';
import { toLower } from 'lodash';
import { sendAnalytics } from '../../../../helper';

interface ChildComponentProps {
  form: Form;
  dateField: Field<Date | null>;
  accountNumberField: Field<string>;
  onSubmit: (responseCode: string, additionalParam?: any) => void;
}

export const FormComponent: React.FC<ChildComponentProps> = ({
  form,
  dateField,
  accountNumberField,
  onSubmit,
}) => {
  const [toastId, setToastid] = useState('');
  const { addToast, removeAllToasts } = useToastContainer();
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [selectedOption, setSelectedStatus] = useState<string>('');
  const [isSelectBtnselected, setSelectBtnselected] = useState(false);
  const [flag, setFlag] = useState<string | null>(null);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    const additionalParam = {
      custIdentifier: accountNumberField.value,
      dateOfBirth: formatDate(dateField.value),
      testResult: selectedOption,
      inputType: 'Initial',
    };
    try {
      setSubmitLoading(true);

      const response = await agentSatellite.post('/order/satelliteMigrateOrdersProcessor',additionalParam);
      const data = await response.data;
      setFlag(data.responseCode);
      if (toLower(additionalParam.testResult) == toLower('Fail')) {
        if (data.responseCode !== '102') {
          onSubmit('NotYetReady', additionalParam);
        }
      } else {
        onSubmit(data.responseCode + '', additionalParam);
      }
      sendAnalytics("submit_clicked", {accountNumber:additionalParam.custIdentifier, toggleState:additionalParam.testResult == 'Pass' ? 'Good to go' : 'Not yet ready', alertMessage:(data.responseCode != '100' && data.responseCode != '101') ? data.responseCode + " - The Account or Smartcard number and account holder’s date of birth details don’t match. Please check and try again." : "success"});

    } catch {
      setSubmitLoading(false);
      sendAnalytics("submit_clicked", {accountNumber:additionalParam.custIdentifier, toggleState:additionalParam.testResult == 'Pass' ? 'Good to go' : 'Not yet ready', alertMessage:"Sorry, there was a problem submitting your order. Please try again. If the problem persists please contact the Sky team on 09 525 5555"});
    }
    setSubmitLoading(false);
  };

  function showToast() {
    const toastID = addToast({
      title: 'Alert',
      message: `Sorry, there was a problem submitting your order. Please try again. If the problem persists please contact the Sky team on 09 525 5555`,
      type: 'error',
      onClose: async () => {
        setToastid('');
      },
      closeText: `Close`,
    });
    setToastid(toastID);
  }
  const currentDate = new Date();
  const maxDateLimit = subYears(currentDate, 99);
  const validateForm = (form.valid ? true : false) && isSelectBtnselected;
  const isSubmitBtnEnabled = validateForm;
  const formatDate = (date: Date | null): string => {
    if (!date) return 'No date selected';
    return format(date, 'dd-MM-yyyy');
  };

  const scrollToFaq = (event: React.FormEvent) => {
    event.preventDefault();
    const targetElement = document.getElementById(
      'How-do-I-find-my-Smartcard-number?',
    );
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedStatus(event.target.value);
    setSelectBtnselected(true);
  };

  return (
    <FormElement
      onSubmit={async () => {
        try {
          setSubmitLoading(true);
          handleSubmit;
        } catch (err) {
          showToast();
        } finally {
          setSubmitLoading(false);
        }
      }}
    >
      <div className="flex flex-col w-full">
        <label className="sky-h6-reg md:starter-text font-bold my-2">
          Account or Smartcard number
        </label>
        <button
          className="flex items-start pb-2"
          onClick={scrollToFaq}
          style={{ color: 'blue', textDecoration: 'none', cursor: 'pointer' }}
        >
          Where do I find these?
        </button>
        <ValidatedTextInput
          placeholder="00000000000000"
          fieldValidation={accountNumberField}
          formValidation={form}
          onChange={accountNumberField.props.onChange}
        />
        <label className="sky-h6-reg md:starter-text font-bold my-2">
          Account Holder's Date of Birth
        </label>
        <ValidationDatePicker
          fieldValidation={dateField}
          placeholderText="dd/mm/yyyy"
          id="book-a-technician-date"
          formValidation={form}
          className="sky-input sky-input--text sky-h7-reg w-full"
          minDate={maxDateLimit}
          maxDate={currentDate}
          selected={dateField.value!}
          showYearDropdown
          showMonthDropDown
        />
        <label className="sky-h6-reg md:starter-text font-bold my-2">
          According to Channel 888 I'm:
        </label>
        <RadioButtonComponent
          id="Pass"
          label="Good to go"
          name="Pass"
          value="Pass"
          checked={selectedOption === 'Pass'}
          onChange={handleRadioChange}
        />
        <RadioButtonComponent
          id="Fail"
          label="Not yet ready"
          name="Fail"
          value="Fail"
          checked={selectedOption === 'Fail'}
          onChange={handleRadioChange}
        />
        {flag == '102' && (
          <label className="sky-h6-reg md:starter-text font-bold my-2 text-red-500">
            The Account or Smartcard number and account holder’s date of birth
            details don’t match. Please check and try again.
          </label>
        )}
        <div className="flex justify-end mt-6">
          <Button
            colorMode={isSubmitBtnEnabled ? 'pure' : 'disabled'}
            disabled={isSubmitLoading || !isSubmitBtnEnabled}
            className="addboxbtn w-full md:w-60 ml-0 "
            type="submit"
            onClick={handleSubmit}
          >
            {isSubmitLoading ? (
              <div className="relative h-4 w-24">
                <Loader bgColor=""></Loader>
              </div>
            ) : (
              'Submit'
            )}
          </Button>
        </div>
      </div>
    </FormElement>
  );
};
