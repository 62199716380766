import * as React from 'react';
import { Content } from './'
import { Header } from '@sky-tv-group/components'
import { useAuth0, getUser} from '../store/authStore/authStore';
import useStore from '../store';


interface LayoutProps {
    data: any;
}

const Layout: React.FC<LayoutProps> = ({ data  }) => {
    const logout = useAuth0(s => s.logout);
    const login = useAuth0(s  => s.login);
    const { isAuthenticated } = useAuth0();
    const navLinks = useStore(s => s.navLinks);
    const profile = useStore(s  => s.profile);
    const userData = getUser();
    const fetchNavLinks = useStore(s => s.fetchNavLinks);
    const logoutHandler = async () => {logout();};
    
    React.useEffect(() => {
        fetchNavLinks();
    }, [fetchNavLinks]);


        const sendBanner = async (event_name: string, link_text: string, link_url: string, promotion_creative: any, promotion_name: any, type: any): Promise<void> => {
            const segmentSent = new Promise<void>((resolve, reject) => {
                if (promotion_name) {
                    window.analytics?.track(event_name, {
                        "promotion_button_text": link_text,
                        "promotion_creative": promotion_creative,
                        "promotion_name": promotion_name
                    }, {});
                }
                resolve();
            });

            return segmentSent.then(() => {
                if (type !== "discover-panel") {
                    // console.log(event_name, link_text, link_url, promotion_creative, promotion_name, type)
                    setTimeout(()=>window.location.href=link_url, 500)
                }
            });
        }

        const sendStandard = async (url: any, event_name: string, link_text: string, link_url: string): Promise<void> => {
            const segmentSent = new Promise<void>((resolve, reject) => {
                window.analytics?.track(event_name, {
                        "link_text": link_text,
                        "link_url": link_url,
                    }, {});
                resolve();
            });

            return segmentSent.then(() => {
                // console.log(event_name, link_text, link_url)
                setTimeout(()=>url === true ? window.location.href=link_url : null, 500);
            });
        }

        const sendProduct = async (event_name: string, cart_type: string, package_type: string, brand: any, category: any, name: any, price: any, quantity: any, id: any, variant: any): Promise<void> => {
            const segmentSent = new Promise<void>((resolve, reject) => {
                window.analytics?.track(
                    event_name, {
                        "cart_type": cart_type,
                        "package_type": package_type,
                        "brand": brand,
                        "category": category,
                        "name": name,
                        "price":price,
                        "quantity": quantity,
                        "id": id,
                        "variant": variant
                    }, {});
                resolve();
            });
    
            return segmentSent.then(() => {
                // console.log("sentProduct: ", event_name, cart_type, package_type, brand, category, name, price, quantity, id, variant)
            });
        }
    


    return (
        <main className="pt-[0px]">
            <div className="fixed w-[100vw] z-[12]">
                <Header  
                    isSignedIn={isAuthenticated}
                    name={userData?.nickname || userData?.given_name}
                    SessionClickHandler={isAuthenticated ? logoutHandler : login}
                    links={navLinks}  
                />
            </div>
            <div id="content" className="pt-[40px] md:pt-[60px]">
                {data.content?.fields ?
                    <Content content={data.content.fields.content} links={navLinks} sendProduct={sendProduct} sendBanner={sendBanner} sendStandard={sendStandard} />
                : null }
            </div>
        </main>
    )
}

export default Layout;
