import * as React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

interface FullPriceProps {
    module: any;
    width: number;
    height: number;
    sendBanner: any;
}

const FullPrice: React.FC<FullPriceProps> = ({ module, width, height, sendBanner}) => {
    const dollarPrice = String(module.fields.productPrice).split(".").shift();
    const centsPrice = String(module.fields.productPrice).split(".").pop();

    return (
        <div className="content-banner relative full-offer overflow-hidden bg-cover bg-no-repeat bg-top max-w-[2000px] w-full"
        style={{height: height, backgroundImage: width > 1024 ? `url('`+module.fields.heroImage?.fields.file.url+`')` : `url('`+module.fields.heroImageMobile?.fields.file.url+`')`}}>
        <div className="absolute bottom-0 p-[20px] w-full">
            <div className="max-w-[1280px] mx-auto p-[20px]">
                <div className="content relative h-full">
                    <div className="text-white leading-[24px] text-[18px] mt-[20px] w-[85%] lg:w-[35%] lg:leading-[32px] lg:text-[28px] lg:mt-[30px] sky-h2">
                        {documentToReactComponents(module.fields.heading)}
                    </div>
                    <div className="text-white leading-[24px] text-[16px] w-[85%] md:text-[18px] lg:w-[35%] lg:leading-[28px] lg:text-[22px] lg:mb-[30px]">
                        {documentToReactComponents(module.fields.detailParagraph)}
                    </div>
                    {module.fields.productPrice > 0 &&
                        <div className="relative bg-contain bg-center lg:bg-auto lg:-left-[20px] h-[80px] w-[240px] lg:w-auto lg:h-auto mt-[40px] pt-[10px]">
                            <div className="lozenge bg-white w-[250px]">
                                <div className="sky-h1-bold text-sky-dark-blue relative pl-[20px]">
                                    <span className="p-0 m-0 text-[35px] absolute top-[10px] ml-[20px]">$</span><span className="text-[83px] ml-[22px]">{dollarPrice}</span>
                                    <span>
                                        <span className="text-[52px] absolute top-[13px] lg:top-[5px]">{centsPrice}</span>
                                        {
                                            module.fields.priceFrequency && 
                                            <>
                                                <br/>
                                                <span className="text-[56px] absolute top-[20px] ml-[115px]">.</span><span className="text-[13px] absolute top-[60px] ml-[130px]">/{module.fields.priceFrequency[0]}</span>
                                            </>
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="flex space-between max-w-[420px] mt-[40px]">
                        { module.fields.callToActionText ?
                            <button className="sky-button sky-button--primary-pure relative" onClick={(e)=>{ e.preventDefault(); sendBanner("Promotion Clicked ", module.fields.callToActionText, module.fields.callToActionLink, module.fields.heroImage.fields.file.url, module.fields.heading?.content[0]?.content[0]?.value, "banner")}}>{module.fields.callToActionText}</button>
                        : null }
                        { module.fields.callToActionSecondaryText ?
                            <button className="sky-button sky-button--secondary-white" onClick={(e)=>{ e.preventDefault(); sendBanner("Promotion Clicked ", module.fields.callToActionSecondaryText, module.fields.callToActionLinkSecondary, module.fields.heroImage?.fields.file.url, module.fields.heading?.content[0]?.content[0]?.value, "banner")}}>{module.fields.callToActionSecondaryText}</button>
                        : null }
                        </div>
                    <div className="mt-[20px] text-[12px] text-white">
                        {documentToReactComponents(module.fields.copyrightParagraph)}
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
}

export default FullPrice;