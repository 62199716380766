import { Layout } from '../components'

const Errors = () => {
    const error ={error: 404}
    return (
        <Layout data={error}/>
    );
    
}

export default Errors;