import * as React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { HomePageProductCard } from './homepageproductcard';
import Slider from 'react-slick';
import { ReactComponent as NextIcon } from '../../../assets/icons/icon_right_arrow.svg';
import { ReactComponent as PrevIcon } from '../../../assets/icons/icon_left_arrow.svg';
import { segment } from '@sky-tv-group/shared';

interface CardPanelProps {
  module: any;
  sendProduct: any;
}

const CardPanel: React.FC<CardPanelProps> = ({ module, sendProduct }) => {

  React.useEffect(() => {
    const mobile = document.querySelectorAll('.scroll-on-mobile');
    mobile.forEach((item) => {
      item.scrollLeft = 300;
    });
  }, []);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 3.1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 2,
          initialSlide: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.05,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  function NextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          zIndex: 50,
          position: 'absolute',
          right: -35,
          top: 260,
        }}
        onClick={onClick}
      >
        <NextIcon />
      </div>
    );
  }

  function PrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          zIndex: 50,
          position: 'absolute',
          left: -80,
          top: 260,
        }}
        onClick={onClick}
      >
        <PrevIcon />
      </div>
    );
  }

  return (
    <section className="section-card-panel pt-[40px] pl-[20px] pr-[0px] lg:px-[30px] mx-auto max-w-[1280px]">
      <div className="text-center font-skyBlack text-black" style={{fontWeight: 'bolder'}}>
        {documentToReactComponents(module.cardPanelHeading)}
      </div>
      <div className="product-slider slider max-w-[1200px] mx-auto">
        <Slider {...settings}>
          {module.cardsToShow.map((card: any, index: number) => {
            const cardData = card.fields;

            return (cardData.type[0] === 'Bundle') ? (
              <div
                className="slide px-sky-sm flex h-full pt-14 pl-0 "
                key={index}
                style={{ marginTop: "10px" }}
              >
                {cardData.lozengeText ? (
                  <div
                    className="font-skyBook text-white relative"
                    style={{
                      background: "linear-gradient(6deg, #254ab3, #f94cb1, #ff9e1c )",
                      fontSize: "20px",
                      lineHeight: "24px",
                      textAlign: "center",
                      padding: "8px",
                      fontWeight: 'bold',
                      borderRadius: "8px 8px 0px 0px",
                      height: '40px',
                      top: '-37px',
                      zIndex: 1,
                    }}
                  >{cardData.lozengeText}</div>
                ) : null}
                <HomePageProductCard
                  kkService={null as any}
                  handleProductCardCTA={() => segment.promotionClicked(cardData.title, cardData.thumbnail?.fields?.file?.url ?? '', "bundle offers")}
                  card={{
                    addLink: cardData.addLink,
                    bgColor: cardData.bgColor ? cardData.bgColor : '#FFFFFF',
                    billingFrequency: cardData.priceFrequencyType[0],
                    imageSrc: {
                      text: cardData.thumbnail?.fields?.file?.text ?? '',
                      url: cardData.thumbnail?.fields?.file?.url ?? '',
                    },
                    lozengeText: cardData.lozengeText,
                    sku: cardData.sku,
                    subtitle: cardData.description,
                    discountedPrice: cardData.acquistionOfferPrice ?? cardData.price,
                    title: cardData.title,
                    type: cardData.type,
                    coupon: null,
                    termsAndConditions: cardData.termsAndConditionsCaption,
                    descriptionList: cardData.detailsList,
                    copyrightText: cardData.copyrightText,
                    ctaName: cardData.ctaName,
                    iconList: cardData.iconList?.map((icon: any) => ({
                      url: icon?.fields?.file?.url ?? '',
                      text: icon?.fields?.file?.text ?? '',
                    })),
                    discountText: cardData.discountText,
                    fromPrice: cardData.acquistionOfferPrice ? cardData.price : null,
                    detailsText: cardData.detailsText,
                    detailsLink: cardData.detailsLink,
                  }}
                />
              </div>
            ) : null;
          })}
        </Slider>
      </div>    
      <div className="text-[12px] mb-[20px] lg:mb-[64px] mt-[40px] lg:mt-[50px] w-full max-w-[85vw]">
        {documentToReactComponents(module.termsAndConditions)}
      </div>
    </section>
  );
};

export default CardPanel;
