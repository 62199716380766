import { validateDateOfBirth, validationNumber } from "@sky-tv-group/shared";
import { useField } from "react-jeff";

export function useDateOfBirthField() {
    return useField<null | Date, string>({
        defaultValue: null,
        required: true,
        validations: [validateDateOfBirth]
    })
}

export function useAccountNumber() {
    return useField({
        defaultValue: '',
        required: true,
        validations: [validationNumber]
    })
}

export function useChannel888SelectionTypes() {
    return useField({
        defaultValue: '',
        required: true,
        validations: []
    })
  }


