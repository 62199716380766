import axios from 'axios';

import {
  MyAccountService,
  T_EPG_Remote_Record,
  SentryService,
  NavigationService,
  NotificationService,
  KonakartService,
  useSubscribedPackages,
  STAFF_ACCOUNT_TYPE,
  updateStaffAccountQuantity,
  MyAccountSWR,
  getTreatment,
  SPLITIO_KEY,
} from '@sky-tv-group/shared';

import { getClient } from '../store/authStore/authStore';
import {
  ACCOUNT_API,
  SENTRY_DSN,
  NAVIGATION_URL,
  LIFERAY_CONNECT_ENDPOINT,
  KONAKART_ENDPOINT,
  APP_ENVIRONMENT,
  SENTRY_DENY_URLS,
  SPACEID,
  DELIVERYTOKEN,
  ENVIRONMENT,
  HOST,
} from '../config';
import { createClient } from 'contentful';

const sentryService = new SentryService(SENTRY_DSN, APP_ENVIRONMENT, SENTRY_DENY_URLS);

const agentMyAccount = axios.create({
  baseURL: ACCOUNT_API,
});

const agentNavigation = axios.create({
  baseURL: NAVIGATION_URL,
});

const agentLiferayConnect = axios.create({
  baseURL: LIFERAY_CONNECT_ENDPOINT,
});

const agentKK = axios.create({
  baseURL: KONAKART_ENDPOINT,
});

const agentSatellite = axios.create({
  baseURL: ACCOUNT_API,
});

const agentContentfulClient = createClient({
  space: SPACEID,
  accessToken: DELIVERYTOKEN,
  environment: ENVIRONMENT,
  host: HOST
})

const kkService = new KonakartService(agentKK, LIFERAY_CONNECT_ENDPOINT);

const navService = new NavigationService(agentNavigation);

agentMyAccount.interceptors.request.use(async (config: any) => {
  const accessToken = await getClient()?.getTokenSilently();
  config.headers['authorization'] = `Bearer ${accessToken}`;
  return config;
});

const myAccountService = new MyAccountService(agentMyAccount);

export const myAccountSWR = new MyAccountSWR(myAccountService, kkService);

const { getSubscribedPackages } = useSubscribedPackages();

const getBoxes = myAccountService.getBoxes;

const getProfile = myAccountService.getProfile;

const remoteRecord = async (payload: T_EPG_Remote_Record) => {
  return myAccountService.remoteRecord(payload);
};

const notificationService = new NotificationService(agentLiferayConnect);

const getProducts = kkService.getAllProduct;

/**
 * Billing logic to add quantity to staff accounts as they have free quantity as positive,
 * but paid quantity as 0. This prevents staff from recording as it does not show items in their
 * paid subscription.
 * Manually sets quantity of some products to 1 for active cable service occurrences
 */
const getBillingWithStaffLogic = async () => {
  const customer = await myAccountService.getCustomer();
  const billing = await myAccountService.getBilling();
  const staffAccount = customer?.type === STAFF_ACCOUNT_TYPE ? true : false;
  if (billing?.services?.CableServiceOccurrence) {
    billing.services.CableServiceOccurrence = updateStaffAccountQuantity(
      staffAccount,
      billing.services.CableServiceOccurrence
    );
  }
  return billing;
};

const getBilling = getBillingWithStaffLogic;

/**
 * get the user's subscription packages and
 * return the user subscription's list of packages.
 */
const getPackagesSubscription = async () => {
  const overrideProductInfoFromKK = await getTreatment(SPLITIO_KEY.SKYWEB_OVERRIDE_PRODUCT_INFO_FROM_KONAKART);
  const billing = await getBilling();
  const products = await getProducts(overrideProductInfoFromKK ? true : false);
  return await getSubscribedPackages(products, billing);
};

export {
  sentryService,
  navService,
  notificationService,
  getBoxes,
  getProfile,
  remoteRecord,
  getPackagesSubscription,
  getProducts,
  myAccountService,
  agentSatellite,
  agentContentfulClient
};
